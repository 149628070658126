body { background-color: $COL12; }
body { color: $COL13; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL17;
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0.075em;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: $COL18;
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0.075em;
font-size: 22px;

@media #{$medium-up} {
font-size: 32px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: $COL19;
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0.075em;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL20;
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 24px;

}
}
h5 {
color: $COL21;
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0.075em;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL22;
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0.075em;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL24;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 18px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL23;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 22px;

}
}
summary {
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0.0625em;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 14px;

}
@media #{$large-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 14px;

}
@media #{$large-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: $COL15}
a:hover {color: $COL16}
/* Primary:2 */
.MES2 {
background-color: $COL2;
color: $COL35;
&:hover { color: $COL25;}
 }
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL35;
 }
.MES3 {
background-color: $COL2;
color: $COL35;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL35;
 }
 }
cite.MEC3{
color: $COL35;
}
/* Secondary:4 */
.MES4 {
background-color: $COL3;
color: $COL37;
&:hover { color: $COL26;}
 }
/* Secondary:5 */
.MES5 {
background-color: $COL3;
color: $COL37;
 }
.MES5 {
background-color: $COL3;
color: $COL37;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL37;
 }
 }
cite.MEC5{
color: $COL37;
}
/* Alternate:6 */
.MES6 {
background-color: $COL4;
color: $COL38;
&:hover { color: $COL27;}
 }
/* Alternate:7 */
.MES7 {
background-color: $COL4;
color: $COL38;
 }
.MES7 {
background-color: $COL4;
color: $COL38;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL38;
 }
 }
cite.MEC7{
color: $COL38;
}
/* Dark:8 */
.MES8 {
background-color: $COL6;
&:hover { color: $COL31;}
 }
/* Dark:9 */
.MES9 {
background-color: $COL5;
 }
.MES9 {
background-color: $COL5;
 }
/* Dark:10 */
.MES10 {
background-color: $COL6;
 }
.MES10 {
background-color: $COL6;
 }
/* Light:11 */
.MES11 {
background-color: $COL7;
 }
/* Light:12 */
.MES12 {
background-color: $COL8;
color: $COL2;
&:hover { color: $COL3;}
 }
/* Light:13 */
.MES13 {
background-color: $COL8;
 }
.MES13 {
background-color: $COL8;
 }
/* Light:14 */
.MES14 {
background-color: $COL7;
 }
.MES14 {
background-color: $COL7;
 }
/* Shade 1:15 */
.MES15 {
background-color: $COL9;
 }
/* Shade 1:16 */
.MES16 {
background-color: $COL10;
&:hover { color: $COL32;}
 }
/* Shade 1:17 */
.MES17 {
background-color: $COL9;
 }
.MES17 {
background-color: $COL9;
 }
/* Shade 1:18 */
.MES18 {
background-color: $COL10;
 }
.MES18 {
background-color: $COL10;
 }
/* Background Image panel:19 */
.MES19 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://melbourneboatsales.com.au/img/35/14');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL11;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
 }
.MES19 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://melbourneboatsales.com.au/img/35/14');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL11;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL11;
 }
 }
cite.MEC19{
color: $COL11;
}
/* Header Panel:20 */
.MES20 {
background-color: $COL2;
color: $COL12;
 }
.MES20 {
background-color: $COL2;
color: $COL12;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL11;
 }
&:hover { h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL40; } }
 }
a.MEC20 { color: $COL11;
&:hover { color: $COL4; }
 }
cite.MEC20{
color: $COL12;
}
/* Menus:21 */
nav.me-Menu.MES21 {
& .menu-item.MEC21, & .menu-item.MEC21 > div.MEC21{ & > a.MEC21{color: $COL11;
text-transform: uppercase;
}
 &:hover > a.MEC21{color: $COL45;
}
 }
&.horizontal > .menu-item.MEC21 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC21 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC21 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC21 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 10px;}

& .sub-menu .menu-item a{padding: 5px;}



&.horizontal .menu-item.MEC21:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL46;}}
&.vertical .menu-item.MEC21:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL46;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC21{background-color: $COL46;
}
& .menu-item.MEC21, & .menu-item.MEC21 > div.MEC21{ & > a.MEC21{color: $COL11;
}
 &:hover > a.MEC21{color: $COL45;
}
 }

}
}
 }
/* Separator line:23 */
.MES23 {
padding: 30px 0;

& > hr {border-width: 1px;}
& > hr {border-color: $COL9 $COL1 $COL1 $COL1;}
& > hr {border-top-style: dotted;}
& > hr {border-right-style: dotted;}
& > hr {border-bottom-style: dotted;}
& > hr {border-left-style: dotted;}
 }
/* Credit Panel:24 */
.MES24 {
font-size: 11.9px;
@media #{$medium-up} {
font-size: 12.6px;
};
@media #{$large-up} {
font-size: 10.8px;
};
 }
.MES24 {
font-size: 11.9px;
@media #{$medium-up} {
font-size: 12.6px;
};
@media #{$large-up} {
font-size: 10.8px;
};
 }
a.MEC24 { color: $COL11;
&:hover { color: $COL11; }
 }
cite.MEC24{
font-size: 11.9px;
@media #{$medium-up} {
font-size: 12.6px;
};
@media #{$large-up} {
font-size: 10.8px;
};
}
/* Footer Panel:25 */
.MES25 {
background-color: $COL2;
color: $COL37;
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 14.4px;
};
 }
.MES25 {
background-color: $COL2;
color: $COL37;
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 14.4px;
};
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL12;
 }
 }
a.MEC25 { color: $COL11;
&:hover { color: $COL4; }
 }
cite.MEC25{
color: $COL37;
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Separator line:26 */
.MES26 {
padding: 5px 0;

& > hr {border-width: 1px;}
& > hr {border-color: $COL9 $COL1 $COL1 $COL1;}
& > hr {border-top-style: dotted;}
& > hr {border-right-style: dotted;}
& > hr {border-bottom-style: dotted;}
& > hr {border-left-style: dotted;}
 }
/* Credit Panel:27 */
.MES27 {
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 14.4px;
};
 }
.MES27 {
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 14.4px;
};
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: $COL12;
 }
 }
a.MEC27 { color: $COL11;
&:hover { color: $COL11; }
 }
cite.MEC27{
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Specs Panel:28 */
.MES28 {
font-size: 13.6px;
@media #{$medium-up} {
font-size: 18px;
};
 }
.MES28 {
font-size: 13.6px;
@media #{$medium-up} {
font-size: 18px;
};
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: $COL5;
 }
h1.MEC28 { font-size: 17.5px; }
h1.MEC28 { @media #{$medium-up} { font-size: 31.5px; }; }
h1.MEC28 { @media #{$large-up} { font-size: 32px; }; }
h2.MEC28 { font-size: 15.4px; }
h2.MEC28 { @media #{$medium-up} { font-size: 28.8px; }; }
h2.MEC28 { @media #{$large-up} { font-size: 28px; }; }
h3.MEC28 { font-size: 14px; }
h3.MEC28 { @media #{$medium-up} { font-size: 27px; }; }
h3.MEC28 { @media #{$large-up} { font-size: 24px; }; }
h4.MEC28 { font-size: 12.6px; }
h4.MEC28 { @media #{$medium-up} { font-size: 21.6px; }; }
h4.MEC28 { @media #{$large-up} { font-size: 19.2px; }; }
h5.MEC28 { font-size: 11.9px; }
h5.MEC28 { @media #{$medium-up} { font-size: 18px; }; }
h5.MEC28 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC28 { font-size: 11.2px; }
h6.MEC28 { @media #{$medium-up} { font-size: 16.2px; }; }
h6.MEC28 { @media #{$large-up} { font-size: 14.4px; }; }
 }
cite.MEC28{
font-size: 13.6px;
@media #{$medium-up} {
font-size: 18px;
};
}
/* Enquiry Button:30 */
.MES30 {
background-color: $COL3;
color: $COL12;
&:hover { color: $COL12;}
font-size: 18px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 16px;
};
padding: 10px 30px;

 }
/* Boat Carousel Style:31 */
.MES31 {
& .slider-arrow {color: $COL9;
font-size: 30px;
@media #{$medium-up} {
font-size: 40px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slider-arrow:hover{color: $COL3;
} }
/* Home Overlay:32 */
.MES32 {
background-color: $COL43;
color: $COL12;
padding: 15px;

 }
.MES32 {
background-color: $COL43;
color: $COL12;
padding: 15px;

h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: $COL12;
 }
 }
cite.MEC32{
color: $COL12;
}
/* Specs Accordion:33 */
details.MES33 {
& > summary {border-radius: 5px;}
& > summary{padding: 10px;}

& > article{padding: 15px;}

& > summary{border-width: 1px;
border-style:solid;
border-color: $COL5;
}& > summary {& > div  > i{color: $COL5;
font-size: 20px;
@media #{$medium-up} {
font-size: 20px;
};
@media #{$large-up} {
font-size: 20px;
};
}}
& > summary { color: $COL5;
text-transform: uppercase
 }
 }
/* Home Slider Mask:34 */
.MES34 {
background-color: $COL42;
color: $COL11;
font-size: 17px;
@media #{$medium-up} {
font-size: 18px;
};
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
.MES34 {
background-color: $COL42;
color: $COL11;
font-size: 17px;
@media #{$medium-up} {
font-size: 18px;
};
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL11;
 }
h1.MEC34 { @media #{$medium-up} { font-size: 28px; }; }
h1.MEC34 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC34 { @media #{$medium-up} { font-size: 25.6px; }; }
h2.MEC34 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC34 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC34 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC34 { @media #{$medium-up} { font-size: 19.2px; }; }
h4.MEC34 { @media #{$large-up} { font-size: 24px; }; }
h5.MEC34 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC34 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC34 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC34 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC34{
color: $COL11;
font-size: 17px;
@media #{$medium-up} {
font-size: 18px;
};
}
/* Featured Boat Panel:35 */
.MES35 {
padding: 10px 5px;

 }
.MES35 {
padding: 10px 5px;

 }
/* Search wrap:36 */
.MES36 {
background-color: $COL40;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 5px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
.MES36 {
background-color: $COL40;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 5px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
/* Search Button:37 */
.MES37 {
background-color: $COL2;
&:hover { background-color: $COL3;}
color: $COL12;
&:hover { color: $COL12;}
font-size: 18px;
@media #{$medium-up} {
font-size: 13.5px;
};
@media #{$large-up} {
font-size: 15px;
};
padding: 7px 10px;

@media #{$medium-up} {
padding: 8px 10px;

}
@media #{$large-up} {
padding: 7px 10px;

}
 }
/* Home CTA:38 */
.MES38 {
background-color: $COL11;
&:hover { background-color: $COL40;}
color: $COL2;
&:hover { color: $COL3;}
 }
/* CTA Panel:39 */
.MES39 {
background-color: $COL3;
&:hover, &.hover { background-color: $COL3;}
color: $COL11;
padding: 15px;

@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 20px;

}
 }
.MES39 {
background-color: $COL3;
&:hover, &.hover { background-color: $COL3;}
color: $COL11;
padding: 15px;

@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 20px;

}
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL11;
 }
&:hover { h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL11; } }
 }
a.MEC39 { color: $COL11;
&:hover { color: $COL11; }
 }
cite.MEC39{
color: $COL11;
}
/* Home Panel Pad:40 */
.MES40 {
padding: 15px;

@media #{$medium-up} {
padding: 60px 20px 180px 20px;

}
@media #{$large-up} {
padding: 160px 30px 200px 30px;

}
 }
.MES40 {
padding: 15px;

@media #{$medium-up} {
padding: 60px 20px 180px 20px;

}
@media #{$large-up} {
padding: 160px 30px 200px 30px;

}
 }
/* Home Panel:41 */
.MES41 {
background-color: $COL43;
&:hover, &.hover { background-color: $COL44;}
color: $COL35;
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 18px;
};
 }
.MES41 {
background-color: $COL43;
&:hover, &.hover { background-color: $COL44;}
color: $COL35;
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 18px;
};
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: $COL35;
 }
h1.MEC41 { font-size: 23.75px; }
h1.MEC41 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC41 { font-size: 20.9px; }
h2.MEC41 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC41 { font-size: 19px; }
h3.MEC41 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC41 { font-size: 17.1px; }
h4.MEC41 { @media #{$large-up} { font-size: 24px; }; }
h5.MEC41 { font-size: 16.15px; }
h5.MEC41 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC41 { font-size: 15.2px; }
h6.MEC41 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC41{
color: $COL35;
font-size: 15.3px;
@media #{$medium-up} {
font-size: 16.2px;
};
@media #{$large-up} {
font-size: 18px;
};
}
/* Main Menu:42 */
nav.responsive-menu {
& .menu-item.MEC42, & .menu-item.MEC42 > div.menu-item-wrap{ & > a.MEC42, & > i{color: $COL11;
text-align: center;
}
  }
& .menu-item.MEC42 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* Footer:43 */
nav.me-Menu.MES43 {
& .menu-item.MEC43, & .menu-item.MEC43 > div.MEC43{ & > a.MEC43{color: $COL12;
font-size: 15.3px;
}
 &:hover > a.MEC43{color: $COL45;
}
 }
&.horizontal > .menu-item.MEC43 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC43 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC43 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC43 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Form Button:44 */
.MES44 {
background-color: $COL4;
&:hover { background-color: $COL3;}
color: $COL11;
&:hover { color: $COL11;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
/* Home Overlay White:45 */
.MES45 {
background-color: $COL40;
color: $COL12;
padding: 15px;

 }
.MES45 {
background-color: $COL40;
color: $COL12;
padding: 15px;

h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: $COL12;
 }
 }
cite.MEC45{
color: $COL12;
}
/* Separator Grey line:46 */
.MES46 {
padding: 30px 0;

& > hr {border-width: 1px;}
& > hr {border-color: $COL42 $COL1 $COL1 $COL1;}
& > hr {border-top-style: dotted;}
& > hr {border-right-style: dotted;}
& > hr {border-bottom-style: dotted;}
& > hr {border-left-style: dotted;}
 }
/* Home Overlay Blue:47 */
.MES47 {
background-color: $COL47;
color: $COL12;
padding: 15px;

 }
.MES47 {
background-color: $COL47;
color: $COL12;
padding: 15px;

h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: $COL12;
 }
 }
cite.MEC47{
color: $COL12;
}
/* Image Carousel Padding:48 */
.MES48 {
padding: 0 5px;

 }
.MES48 {
padding: 0 5px;

 }
/* White Panel:49 */
.MES49 {
background-color: $COL11;
 }
.MES49 {
background-color: $COL11;
 }
/* News Block Panel:50 */
.MES50 {
border-width: 1px;
border-style: solid;
border-color: $COL10;
 }
.MES50 {
border-width: 1px;
border-style: solid;
border-color: $COL10;
 }
/* Banner Spacer:51 */
.MES51 {
padding: 15px;

@media #{$large-up} {
padding: 70px 0;

}
 }
/* News Read More:52 */
.MES52 {
background-color: $COL8;
&:hover { background-color: $COL5;}
color: $COL2;
&:hover { color: $COL11;}
font-size: 18px;
@media #{$large-up} {
font-size: 10px;
};
padding: 5px 10px;

 }
/* Background Image panel 2:53 */
.MES53 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://melbourneboatsales.com.au/img/35/14');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL11;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
 }
.MES53 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://melbourneboatsales.com.au/img/35/14');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL11;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: $COL11;
 }
 }
cite.MEC53{
color: $COL11;
}
/* Prod - Separator Grey line:54 */
.MES54 {
padding: 10px 0;

& > hr {border-width: 1px;}
& > hr {border-color: $COL48 $COL1 $COL1 $COL1;}
& > hr {border-top-style: solid;}
& > hr {border-right-style: solid;}
& > hr {border-bottom-style: solid;}
& > hr {border-left-style: solid;}
 }
/* Boat Specs:55 */
details.MES55 {
& > summary{padding: 15px 0;}

& > summary{border-width: 0 0 1px 0;
border-style:solid;
border-color: transparent transparent $COL48 transparent;
}& > summary { color: $COL3;
 }
 }
/* Mobile Detail:56 */
details.MES56 {
& > summary{background-color: $COL2;
&:hover {background-color: $COL2;}
}
& > summary{padding: 10px 5px;}

& > summary{border-width: 0 0 1px 0;
border-style:dashed;
border-color: transparent transparent $COL12 transparent;
}& > summary {& > div  > i{color: $COL1;
font-size: 0px;
}}
& > summary { color: $COL12;
&:hover {color: $COL12}
 }
 }
