$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #000b33; //
$COLFLAT2: #000b33; //
$COL3: #22376e; //
$COLFLAT3: #22376e; //
$COL4: #079fc6; //
$COLFLAT4: #079fc6; //
$COL5: #1b1b1b; //
$COLFLAT5: #1b1b1b; //
$COL6: #1b1b1b; //
$COLFLAT6: #1b1b1b; //
$COL7: #f2f2f2; //
$COLFLAT7: #f2f2f2; //
$COL8: #f2f2f2; //
$COLFLAT8: #f2f2f2; //
$COL9: #e8e8e8; //
$COLFLAT9: #e8e8e8; //
$COL10: #e8e8e8; //
$COLFLAT10: #e8e8e8; //
$COL11: #ffffff; //
$COLFLAT11: #ffffff; //
$COL12: #ffffff; //
$COLFLAT12: #ffffff; //
$COL13: #1b1b1b; //
$COLFLAT13: #1b1b1b; //
$COL14: #1b1b1b; //
$COLFLAT14: #1b1b1b; //
$COL15: #000b33; //
$COLFLAT15: #000b33; //
$COL16: #666666; //
$COLFLAT16: #666666; //
$COL17: #000b33; //
$COLFLAT17: #000b33; //
$COL18: #22376e; //
$COLFLAT18: #22376e; //
$COL19: #666666; //
$COLFLAT19: #666666; //
$COL20: #22376e; //
$COLFLAT20: #22376e; //
$COL21: #22376e; //
$COLFLAT21: #22376e; //
$COL22: #22376e; //
$COLFLAT22: #22376e; //
$COL23: #22376e; //
$COLFLAT23: #22376e; //
$COL24: #000b33; //
$COLFLAT24: #000b33; //
$COL25: #ffffff; //
$COLFLAT25: #ffffff; //
$COL26: #ffffff; //
$COLFLAT26: #ffffff; //
$COL27: #ffffff; //
$COLFLAT27: #ffffff; //
$COL28: #1b1b1b; //
$COLFLAT28: #1b1b1b; //
$COL29: #1b1b1b; //
$COLFLAT29: #1b1b1b; //
$COL30: #ffffff; //
$COLFLAT30: #ffffff; //
$COL31: #ffffff; //
$COLFLAT31: #ffffff; //
$COL32: #1b1b1b; //
$COLFLAT32: #1b1b1b; //
$COL33: #1b1b1b; //
$COLFLAT33: #1b1b1b; //
$COL34: #ffffff; //
$COLFLAT34: #ffffff; //
$COL35: #ffffff; //
$COLFLAT35: #ffffff; //
$COL36: #ffffff; //
$COLFLAT36: #ffffff; //
$COL37: #ffffff; //
$COLFLAT37: #ffffff; //
$COL38: #ffffff; //
$COLFLAT38: #ffffff; //
$COL39: #ffffff; //
$COLFLAT39: #ffffff; //
$COL40: rgba(255,255,255,0.4); //white 40%
$COLFLAT40: #b3b3b3; //white 40%
$COL41: rgba(242,242,242,0.671); //Home Overlay
$COLFLAT41: #cccccc; //Home Overlay
$COL42: rgba(0,0,0,0.4); //black 40%
$COLFLAT42: #4d4d4d; //black 40%
$COL43: rgba(0,0,0,0.588); //black 60%
$COLFLAT43: #353535; //black 60%
$COL44: rgba(0,0,0,0.161); //Black Overlay - light
$COLFLAT44: #6b6b6b; //Black Overlay - light
$COL45: #999999; //Grey Menu Highlight
$COLFLAT45: #999999; //Grey Menu Highlight
$COL46: rgba(0,11,51,0.729); //Blue submenu backgropund
$COLFLAT46: #232b48; //Blue submenu backgropund
$COL47: rgba(0,11,51,0.471); //Blue hover 50%
$COLFLAT47: #44495c; //Blue hover 50%
$COL48: #dadcdf; //Separator colour
$COLFLAT48: #dadcdf; //Separator colour
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
